import React from "react";
import bride from "assets/bride.png";
import { getString, LANG_EN, useLanguageStore } from "stores/language";
import CONSTANTS from "constants";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export default function Bride() {
  const language = useLanguageStore((state) => state.language);
  return (
    <div className="flex animate-fadeIn flex-col tablet:flex-row justify-center">
      <div className="bg-white p-4 rounded-2xl border border-black flex flex-col justify-between w-full tablet:w-[280px]">
        <div className="flex items-end text-sm">
          <img className="w-8 h-8 mr-1" src={bride} alt="" />
          <div>
            {getString(language, "bride")}
            <span className="font-bold text-lg">
              &nbsp;{getString(language, "bride_name")}
            </span>
          </div>
        </div>

        <div className="flex mt-2.5 text-sm" style={{ wordBreak: "keep-all" }}>
          {getString(language, "message")}
        </div>

        <div className="flex mt-2.5">
          <CopyToClipboard
            text={CONSTANTS.BRIDE_BANK}
            onCopy={() =>
              toast.success(
                `${getString(language, "copied")}\n${CONSTANTS.BRIDE_BANK}`
              )
            }
          >
            <div className="bg-[#463b3c] text-xs cursor-pointer text-white px-4 py-2 rounded-3xl">
              {getString(language, "bank")}
            </div>
          </CopyToClipboard>
          <a
            className="bg-[#fff01c] text-[#463b3c] text-xs cursor-pointer px-4 py-2 rounded-3xl ml-1"
            href={CONSTANTS.BRIDE_KAKAOPAY}
            target="_blank"
            rel="noreferrer noopener"
          >
            {getString(language, "kakaopay")}
          </a>
        </div>
      </div>
      <div className="bg-white p-4 rounded-2xl border border-black flex flex-col justify-between w-full tablet:w-[280px] mt-2 tablet:ml-2 tablet:mt-0">
        <div className="flex items-center text-sm">
          {getString(language, "bride_parents")}
        </div>

        <div className={`flex flex-col text-sm mt-2.5 `}>
          <div>
            <span className={`inline-block ${language === LANG_EN && "w-12"}`}>
              {getString(language, "dad")}
            </span>
            <span className="font-bold text-lg">
              &nbsp;{getString(language, "bride_dad")}
            </span>
          </div>
          <div>
            <span className={`inline-block ${language === LANG_EN && "w-12"}`}>
              {getString(language, "mom")}
            </span>
            <span className="font-bold text-lg">
              &nbsp;{getString(language, "bride_mom")}
            </span>
          </div>
        </div>

        <div className="flex mt-2.5">
          <CopyToClipboard
            text={CONSTANTS.BRIDE_PARENTS_BANK}
            onCopy={() =>
              toast.success(
                `${getString(language, "copied")}\n${
                  CONSTANTS.BRIDE_PARENTS_BANK
                }`
              )
            }
          >
            <div className="bg-[#463b3c] text-xs cursor-pointer text-white px-4 py-2 rounded-3xl">
              {getString(language, "bank")}
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}
