import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";

export function Birds() {
  return new Array(4).fill().map((_, i) => {
    const x = (20 + Math.random() * 100) * (Math.round(Math.random()) ? -1 : 1);
    const y = 50 + Math.random() * 50;
    const z = Math.random() * -240;
    const bird = "Stork";
    let speed = bird === 0.25;
    let factor = 0.5 + Math.random();

    return (
      <Bird
        key={i}
        position={[x, y, z]}
        rotation={[0, x > 0 ? Math.PI : 0, 0]}
        speed={speed}
        factor={factor}
      />
    );
  });
}

export default function Bird({ speed, factor, url, ...props }) {
  const { nodes, materials, animations } = useGLTF("/models/stork.glb");
  const group = useRef();
  const mesh = useRef();
  const [start] = useState(() => Math.random() * 5000);
  const [mixer] = useState(() => new THREE.AnimationMixer());
  useEffect(
    () => void mixer.clipAction(animations[0], group.current).play(),
    []
  );
  useFrame((state, delta) => {
    mesh.current.position.y = Math.sin(start + state.clock.elapsedTime) * 25;
    mesh.current.rotation.x =
      Math.PI / 2 + (Math.sin(start + state.clock.elapsedTime) * Math.PI) / 10;
    mesh.current.rotation.y =
      (Math.sin(start + state.clock.elapsedTime) * Math.PI) / 2;
    group.current.rotation.y +=
      Math.sin((delta * factor) / 2) * Math.cos((delta * factor) / 2) * 1.5;
    mixer.update(delta * speed);
  });
  return (
    <group ref={group} dispose={null}>
      <scene name="Scene" {...props}>
        <mesh
          ref={mesh}
          scale={1.5}
          name="Object_0"
          morphTargetDictionary={nodes.Object_0.morphTargetDictionary}
          morphTargetInfluences={nodes.Object_0.morphTargetInfluences}
          rotation={[Math.PI / 2, 0, 0]}
          geometry={nodes.Object_0.geometry}
          material={materials.Material_0_COLOR_0}
        />
      </scene>
    </group>
  );
}
useGLTF.preload("/models/stork.glb");
