import create from "zustand";

export const CLICKED_GROOM = "groom";
export const CLICKED_BRIDE = "bride";
export const CLICKED_MAP = "map";

export const useAppStore = create((set) => ({
  clicked: null,
  mounted: false,
  setClicked: (clicked) => set({ clicked }),
  setMounted: () => set({ mounted: true }),
  galleryVisible: false,
  setGalleryVisible: (galleryVisible) => set({ galleryVisible }),
}));
