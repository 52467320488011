import ImageGallery from "react-image-gallery";
import close from "assets/close.png";
import { useAppStore } from "stores/app";

const images = [
  {
    original: "/pictures/1.JPG",
    thumbnail: "/pictures/1.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/2.JPG",
    thumbnail: "/pictures/2.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/3.JPG",
    thumbnail: "/pictures/3.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/4.JPG",
    thumbnail: "/pictures/4.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/5.JPG",
    thumbnail: "/pictures/5.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/6.JPG",
    thumbnail: "/pictures/6.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/7.JPG",
    thumbnail: "/pictures/7.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/8.JPG",
    thumbnail: "/pictures/8.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/9.JPG",
    thumbnail: "/pictures/9.JPG",
    originalClass: "w-full",
  },
  {
    original: "/pictures/10.JPG",
    thumbnail: "/pictures/10.JPG",
    originalClass: "w-full",
  },
];

export default function Gallery() {
  const galleryVisible = useAppStore((state) => state.galleryVisible);
  const setGalleryVisible = useAppStore((state) => state.setGalleryVisible);
  if (!galleryVisible) return false;
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-80">
      <img
        className="w-8 h-8 absolute top-4 right-4 cursor-pointer z-40"
        src={close}
        onClick={() => setGalleryVisible(false)}
        alt=""
      />
      <ImageGallery items={images} showFullscreenButton={false} />
    </div>
  );
}
