import React, { useEffect } from "react";
import bride from "assets/bride.png";
import groom from "assets/groom.png";
import camera from "assets/camera.png";
import pin from "assets/pin.png";
import heart from "assets/heart.png";
import {
  CLICKED_BRIDE,
  CLICKED_GROOM,
  CLICKED_MAP,
  useAppStore,
} from "stores/app";
import { getString, LANG_EN, LANG_KO, useLanguageStore } from "stores/language";
import kr from "assets/kr.png";
import us from "assets/us.png";
import Gallery from "Gallery";

export default function Header() {
  const setClicked = useAppStore((state) => state.setClicked);
  const setGalleryVisible = useAppStore((state) => state.setGalleryVisible);
  const setLanguage = useLanguageStore((state) => state.setLanguage);
  const language = useLanguageStore((state) => state.language);

  return (
    <header className="text-xs items-start p-2 tablet:p-4 pt-4">
      <Gallery />
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          window.reset();
          setClicked(null);
        }}
      >
        <img className="w-5 h-5 tablet:w-8 tablet:h-8" src={groom} alt="" />
        <img className="w-3 h-3 tablet:w-5 tablet:h-5" src={heart} alt="" />
        <img className="w-5 h-5 tablet:w-8 tablet:h-8" src={bride} alt="" />
      </div>
      <div>
        <div className="flex">
          <div
            onClick={() => {
              setGalleryVisible(true);
            }}
            className="flex items-center link"
          >
            <img
              className="w-3 h-3 tablet:w-4 tablet:h-4 mr-1"
              src={camera}
              alt=""
            />
            {getString(language, "picture")}
          </div>
          <div
            onClick={() => {
              window.groom();
              setClicked(CLICKED_GROOM);
            }}
            className="flex items-center link"
          >
            <img
              className="w-3 h-3 tablet:w-4 tablet:h-4 mr-1"
              src={groom}
              alt=""
            />
            {getString(language, "groom")}
          </div>
          <div
            onClick={() => {
              window.bride();
              setClicked(CLICKED_BRIDE);
            }}
            className="flex items-center link"
          >
            <img
              className="w-3 h-3 tablet:w-4 tablet:h-4 mr-1"
              src={bride}
              alt=""
            />
            {getString(language, "bride")}
          </div>
          <div
            onClick={() => {
              window.card();
              setClicked(CLICKED_MAP);
            }}
            className="flex items-center link"
          >
            <img
              className="w-3 h-3 tablet:w-4 tablet:h-4 mr-1"
              src={pin}
              alt=""
            />
            {getString(language, "info")}
          </div>
        </div>

        {/* <div className="flex mt-2.5 justify-end">
          <img
            className="w-6 h-6 cursor-pointer"
            src={kr}
            alt=""
            onClick={() => setLanguage(LANG_KO)}
          />
          <img
            className="w-6 h-6 ml-2 cursor-pointer"
            src={us}
            alt=""
            onClick={() => setLanguage(LANG_EN)}
          />
        </div> */}
      </div>
    </header>
  );
}
