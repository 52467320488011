import { useTexture } from "@react-three/drei";
import React from "react";

export default function Invitation({ name, rotation, position, src }) {
  const texture = useTexture(src);
  return (
    <group name={name} rotation={rotation} position={position} scale={0.05}>
      <mesh>
        <planeGeometry args={[45.2, 30.7]} />
        <meshBasicMaterial map={texture} />
      </mesh>
    </group>
  );
}
