import { useThree } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { CameraControls } from "./CameraControls";

export default function Camera() {
  const cameraControl = useRef();
  const scene = useThree((state) => state.scene);

  window.reset = async function () {
    const pos = {
      x: -3.2,
      y: 28,
      z: 82,
    };

    //target
    const target = {
      x: -3.2,
      y: 0,
      z: 0,
    };

    cameraControl.current.setPosition(pos.x, pos.y, pos.z, true);
    cameraControl.current.setTarget(target.x, target.y, target.z, true);
  };

  window.card = async function () {
    const map = scene.getObjectByName("map");
    // cameraControl.current.fitToBox(map, true);
    const pos = {
      x: 8.7,
      y: 10.8,
      z: -1.1,
    };
    const target = map.position;
    const override = {};

    if (window.innerWidth < 768) {
      pos.x = 8.5;
      pos.y = 11;
      pos.z = 0;
      override.y = -2;
      override.z = 0;
    }

    // //target
    // const target = {
    //   x: 8.712416585977588,
    //   y: -2.8568378255598432,
    //   z: -2.1539063833569974,
    // };
    // cameraControl.current.setPosition(pos.x, 200, pos.z, true);

    // cameraControl.current.fitToBox(map, true);
    cameraControl.current.setPosition(pos.x, pos.y, pos.z, true);
    cameraControl.current.setTarget(
      target.x,
      override.y || target.y,
      override.z || target.z,
      true
    );
  };

  window.groom = function () {
    const groom = scene.getObjectByName("groom");
    const pos = {
      x: 1,
      y: 17,
      z: -49,
    };
    const override = {};
    if (window.innerWidth < 768) {
      pos.y = 12;
      override.y = 12;
    }

    const target = groom.position;

    cameraControl.current.setPosition(pos.x, pos.y, pos.z, true);
    cameraControl.current.setTarget(target.x, override.y || 15, target.z, true);
  };

  window.bride = function () {
    const bride = scene.getObjectByName("bride");
    const pos = {
      x: -7,
      y: 17,
      z: -49,
    };
    const override = {};
    if (window.innerWidth < 768) {
      pos.y = 12;
      override.y = 12;
    }

    const target = bride.position;

    cameraControl.current.setPosition(pos.x, pos.y, pos.z, true);
    cameraControl.current.setTarget(target.x, override.y || 15, target.z, true);
  };

  useEffect(() => {
    window.reset();
    cameraControl.current.addEventListener("update", (e) => {
      // console.log(e.target.getPosition(), e.target.getTarget());
    });
  }, []);

  return <CameraControls ref={cameraControl} />;
}
