import Bride from "Bride";
import Groom from "Groom";
import Map from "Map";
import React from "react";
import {
  CLICKED_BRIDE,
  CLICKED_GROOM,
  CLICKED_MAP,
  useAppStore,
} from "stores/app";

export default function Footer() {
  const clicked = useAppStore((state) => state.clicked);
  const galleryVisible = useAppStore((state) => state.galleryVisible);
  if (galleryVisible) return null;
  return (
    <div className="fixed w-screen bottom-0 z-10 p-2 tablet:p-4">
      {clicked === CLICKED_GROOM && <Groom />}
      {clicked === CLICKED_BRIDE && <Bride />}
      {clicked === CLICKED_MAP && <Map />}
    </div>
  );
}
