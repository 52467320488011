const ENGLISH = {
  groom: "Groom",
  groom_name: "Mr. ggoma",
  bride: "Bride",
  bride_name: "Mrs. ggoma",
  date: "08.21.2022 18:00PM",
  info: "Location",
  message:
    "We are leaving the bank account number for those who want to congratulate us remotely. Thank you!",
  groom_dad: "ggoma's dad",
  groom_mom: "ggoma's mom",
  bride_dad: "ggoma's dad",
  bride_mom: "ggoma's mom",
  bank: "Bank Account",
  kakaopay: "KakaoPay",
  eth: "Ethereum",
  venue: "@alldayfresh_kitchen",
  venue_location: "28-1 Yeoui-dong, Yeongdeungpo-gu, Seoul, 50th floor",
  open_map: "Open in Map",
  groom_parents: "Groom's Parents",
  bride_parents: "Bride's Parents",
  dad: "Father",
  mom: "Mother",
  picture: "Pictures",
  copied: "Copied to clipbard",
  twitter: "Twitter",
};

export default ENGLISH;
