const CONSTANTS = {
  GROOM_BANK: "안알랴주지롱",
  GROOM_ETH: "0x735520F20Ec3FAefe91B2a2318b82D6f9e986eA3",
  GROOM_PARENTS_BANK: "안알랴주지롱",
  BRIDE_BANK: "안알랴주지롱",
  BRIDE_KAKAOPAY: "https://twitter.com/0xggoma",
  BRIDE_PARENTS_BANK: "안알랴주지롱",
};

export default CONSTANTS;
