import Footer from "Footer";
import Header from "Header";
import React from "react";
import { useAppStore } from "stores/app";
import Title from "Title";

export default function HtmlOverlay() {
  const mounted = useAppStore((state) => state.mounted);
  if (!mounted) return null;
  return (
    <>
      <Header />
      <Title />
      <Footer />
    </>
  );
}
