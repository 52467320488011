import { Cloud, Html, Sky, useProgress } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import heart from "assets/heart.png";
import { Birds } from "Bird";
import HtmlOverlay from "HtmlOverlay";
import Loading from "Loading";
import React, { Suspense } from "react";
import "./App.css";
import Camera from "./Camera";
import Grass from "./Grass";
import Invitation from "./Invitation";
import Ocean from "./Ocean";
import Wedding from "./Wedding";

function Loader() {
  const { progress, loaded, total } = useProgress();
  return (
    <Html
      center
      className="fixed left-0 top-0 bg-white w-screen h-screen z-30 flex flex-col items-center justify-center pointer-events-none"
      portal={document.getElementById("app")}
    >
      <div className="flex justify-center">
        <img className="animate-ping w-4 h-4" src={heart} alt="" />
      </div>
      <div className="text-center mt-2.5 font-bold uppercase text-xs">
        Filling the air with love
      </div>
      <div
        className="h-[2px] bg-red-500 mt-2.5 transition-all"
        style={{
          width: `${progress}%`,
        }}
      />
      <div className="text-center text-gray-500 text-xs mt-2.5">
        {loaded} / {total}
      </div>
      <div className="text-center text-gray-500 text-xs">
        {progress.toFixed(2)}%
      </div>
    </Html>
  );
}

function App() {
  return (
    <div id="app">
      <HtmlOverlay />
      <Loading />
      <Canvas>
        <Suspense fallback={<Loader />}>
          <Camera />
          <ambientLight args={["white"]} />
          <color attach="background" args={["skyblue"]} />
          {/* <Clouds /> */}

          <Ocean />
          <Cloud
            opacity={1.5}
            position={[0, 450, -230]}
            speed={0.4} // Rotation speed
            width={800} // Width of the full cloud
            depth={0.5} // Z-dir depth
            segments={500} // Number of particles
          />
          <Grass />
          <Sky scale={5000} sunPosition={[300, 100, -500]} turbidity={0.01} />
          <Birds />
          <Invitation
            name="map"
            rotation={[-Math.PI / 2, 0, Math.PI / 4]}
            position={[8.5, 7.36, -2.2]}
            src="/textures/invitation1.png"
          />
          <Invitation
            rotation={[-Math.PI / 2, 0, Math.PI / 3]}
            position={[8.5, 7.35, -0.6]}
            src="/textures/invitation2.png"
          />
          <Wedding />
        </Suspense>
        {/* <Stats /> */}
      </Canvas>
    </div>
  );
}

export default App;
