const KOREAN = {
  groom: "신랑",
  groom_name: "Mr. 꼬마",
  bride: "신부",
  bride_name: "Mrs. 꼬마",
  date: "08.21.2022 18:00PM",
  info: "장소",
  message:
    "비대면으로 축하를 전하려는 분들을 위해 계좌번호를 남깁니다. 진심으로 축하해주셔서 감사합니다.",
  groom_dad: "꼬마아빠",
  groom_mom: "꼬마엄마",
  bride_dad: "꼬꼬마아빠",
  bride_mom: "꼬꼬마엄마",
  bank: "계좌번호",
  kakaopay: "카카오페이",
  eth: "이더리움",
  venue: "세상의 모든 아침 - 여의도점",
  venue_location: "서울 영등포구 여의대로 24 전경련회관 50층",
  open_map: "지도에서 열기",
  groom_parents: "신랑측 혼주",
  bride_parents: "신부측 혼주",
  dad: "아버지",
  mom: "어머니",
  picture: "사진",
  copied: "클립보드에 복사하였습니다",
  twitter: "트위터",
};

export default KOREAN;
