/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF, useTexture } from "@react-three/drei";
import React, { useEffect, useRef } from "react";
import { CLICKED_BRIDE, CLICKED_GROOM, useAppStore } from "stores/app";

export default function Wedding({ ...props }) {
  const group = useRef();
  const setMounted = useAppStore((state) => state.setMounted);
  const setClicked = useAppStore((state) => state.setClicked);
  const { nodes } = useGLTF("/models/wedding.glb");
  const [groomTexture, brideTexture, bgTexture, archTexture, palmsTexture] =
    useTexture([
      "/textures/groom.jpg",
      "/textures/bride.jpg",
      "/textures/bg.jpg",
      "/textures/arch.jpg",
      "/textures/palmsandrock.jpg",
    ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <group ref={group} {...props} dispose={null} position={[0, 1, 0]}>
      <group name="brideandgroom">
        <group
          name="groom"
          className="cursor-pointer"
          position={[-11.03, 8.69, -61.55]}
          rotation={[-Math.PI / 2, 1.55, Math.PI / 2]}
          onClick={() => {
            window.groom();
            setClicked(CLICKED_GROOM);
          }}
        >
          <mesh geometry={nodes.groom.geometry} position={[0, 8.73, 0]}>
            <meshBasicMaterial map={groomTexture} map-flipY={false} />
          </mesh>
        </group>

        <group
          name="bride"
          position={[7.18, 8.6, -61.69]}
          rotation={[Math.PI, -1.53, Math.PI]}
          onClick={() => {
            window.bride();
            setClicked(CLICKED_BRIDE);
          }}
        >
          <mesh geometry={nodes.bride.geometry} position={[0, 8.73, 0]}>
            <meshBasicMaterial map={brideTexture} map-flipY={false} />
          </mesh>
        </group>
      </group>
      <mesh
        name="palms"
        geometry={nodes.palmsandrock.geometry}
        position={[-2.51, 7.51, -61.48]}
        rotation={[0, 1.05, 0]}
      >
        <meshBasicMaterial map={palmsTexture} map-flipY={false} />
      </mesh>
      <mesh
        geometry={nodes.arch.geometry}
        position={[-5.55, 42.53, -41.52]}
        rotation={[0.02, -0.18, 2.92]}
      >
        <meshBasicMaterial map={archTexture} map-flipY={false} />
      </mesh>

      <mesh
        geometry={nodes.bgandchair.geometry}
        position={[0.03, 0, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      >
        <meshBasicMaterial map={bgTexture} map-flipY={false} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/models/wedding.glb");
