import { Html } from "@react-three/drei";
import React from "react";
import { useAppStore } from "stores/app";

export default function Loading() {
  const mounted = useAppStore((state) => state.mounted);
  if (!mounted) return null;

  return (
    <div className="fixed left-0 top-0 bg-black w-screen h-screen z-30 pointer-events-none animate-fadeOut opacity-0" />
  );
}
