import React from "react";
import heart from "assets/heart.png";
import { useAppStore } from "stores/app";
import { getString, LANG_EN, useLanguageStore } from "stores/language";

export default function Title() {
  const clicked = useAppStore((state) => state.clicked);
  const language = useLanguageStore((state) => state.language);
  const galleryVisible = useAppStore((state) => state.galleryVisible);

  if (galleryVisible) return null;

  return (
    <div
      className={`fixed w-screen h-full z-10 flex items-end pb-20 justify-center top-0 text-white font-black ${
        language === LANG_EN
          ? "text-4xl tablet:text-5xl"
          : "text-6xl tablet:text-7xl"
      } pointer-events-none transition-opacity`}
      style={{
        opacity: clicked ? 0 : 1,
      }}
    >
      <div
        className="text-center"
        style={{
          textShadow: "2px 2px #000",
        }}
      >
        <div className="text-lg flex items-center justify-center">
          <img className="w-4 h-4 animate-pulse mr-1" src={heart} alt="" />
          love story of...
        </div>
        <div className="mt-2.5">{getString(language, "groom_name")}</div>
        <div className="text-3xl">&</div>
        <div>{getString(language, "bride_name")}</div>
        <div className="text-lg mt-2.5">{getString(language, "date")}</div>
        <div className="text-lg">{getString(language, "venue")}</div>
      </div>
    </div>
  );
}
