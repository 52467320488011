import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import toast, { ToastBar, Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster position="top-center">
      {(t) => (
        <div
          className="cursor-pointer break-all"
          onClick={() => toast.dismiss(t.id)}
        >
          <ToastBar
            toast={t}
            style={{
              borderRadius: "10px",
            }}
          />
        </div>
      )}
    </Toaster>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
